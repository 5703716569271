<template>
  <div class="tabBox one">
    <h2 class="bold">
      {{ $t("livemap-data-details-title") }}
    </h2>
    <div>
      <div class="map" style="width: 100%;" :style="{ 'height': (typeChangeMapShowHeight - 170) + 'px' }">
        <Map ref="map" style="width : 100%; height: 100%; position: absolute; z-index:1;" :loadMapName="loadMap"/>
        <!-- 임시 화선 데이터 조정 바 -->
        <div class="box" style="position: absolute; z-index: 2000; top: 77px; right: 38px;">
          <div class="progress-bar" style="display: flex; flex-direction: row; ">
            <input style="width: 8px; height: 100%; writing-mode: bt-lr; appearance: slider-vertical;" background-color="red" type="range" id="temp" name="temp" min="0" list="tickmarks">
<!--            <datalist id="tickmarks">
              <option value="100" label="28.2℃"></option>
              <option value="75" label="26.0℃"></option>
              <option value="50" label="23.8℃"></option>
              <option value="25" label="21.6℃"></option>
              <option value="0" label="19.4℃"></option>
            </datalist>-->
          </div>
        </div>
      </div>
      <div class="box one detail" style="padding: 6px 20px; margin-top: 16px;">
        <div class="title liveMapInfo" style="display: inline-block">
          <span class="bold">{{waypointInfo.title}}</span>
          <span>{{ waypointInfo.userName }}</span>
          <span>{{ waypointInfo.userDepartmentName}}</span>
          <span>Mavic 2 pro</span>
          <span>{{ moment(waypointInfo.registerDate).format("YYYY.MM.DD HH:mm")}}</span>
          <button class="medium" style="display: inline-block; float: right;" @click="backToLiveMapList">목록으로</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {fetchWaypointInfo} from  "@/api/missionWaypoint";
import { fetchEvent } from "@/api/event";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import { fetchLocation } from "@/api/location";
import {getUrlLiveMapThumbnail} from "@/api/contents";
import {mapState} from "vuex";

export default {
  components: { Map },
  props: {
    eventId: {
      type: String,
      default: null,
    },
    waypointId: {
      type: String,
      default: null,
    },
    refId: {
      type: String,
      default: null,
    },
    contentsId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      index: 0,
      moment: moment,
      waypointInfo : {},
      waypointLocationList: [],
      mapWidth: 0,
      offset : -1,
      typeChangeMapShowHeight: 0,
      loadMap : 'kakao',
      keywordType: "Red",
    };
  },
  created() {
    this.mapWidth = window.innerWidth - 322;
    this.typeChangeMapShowHeight = this.mapWidth * 0.5625;
    window.addEventListener('resize', this.handleResize);
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  mounted() {
    this.getEvent();
    this.getWaypointInfo();
    this.interval = setInterval(() => {
      this.getLocation();
      // this.getLocationThermal();
    }, 2000);
  },
  methods: {
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.initMapData(this.currentLang);
        }
      });
    },
    getWaypointInfo(){
      fetchWaypointInfo(this.waypointId).then((res) => {
        if (res.data.result == 0) {
          this.waypointInfo = res.data.data;
        }
      })
    },
    getLocation() {
      // let i = 0;
      if(this.refId == null) {
        return;
      }
      let params = {
        refId: this.refId,
        eventId : this.eventId,
        contentsId : this.contentsId,
        isThermal : false,
        offsetIndex : this.offset,
      };

      fetchLocation(params).then((res) => {
        var content =  res.data.data.content;
        let sort = content.sort((a,b) => {return a.index - b.index})
        if(sort.length > 0) {
          this.offset = sort[sort.length - 1].index;
          let mapDataType = MapDataType.ID_LIVE_MAP;
          this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)
          sort.forEach((item) => {
            let mapMarkerData = new MapMarkerData();
            mapMarkerData.id = item.index;
            mapMarkerData.lng = item.lng;
            mapMarkerData.lat = item.lat;
            mapMarkerData.rotate = item.azimuth;
            mapMarkerData.thumbnail = getUrlLiveMapThumbnail(item.liveMapFileId);
            mapMarkerData.liveMapBounds = {
              leftBottom : {lat : item.latLeftBottom, lng : item.lngLeftBottom },
              rightTop : {lat : item.latRightTop, lng : item.lngRightTop }
            }
            if(this.$refs.map.onIsMarker(mapDataType, item.index ) ) {
              this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
              this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }
          })
          //http://localhost:9000/api/v1/thumbnail/file/FL-23b7556b-ebaf-4571-aee2-51c5d513b86
        }
      });
    },
    getLocationThermal() {
      // let i = 0;
      if(this.refId == null) {
        return;
      }
      let params = {
        refId: this.refId,
        eventId : this.eventId,
        contentsId : this.contentsId,
        isThermal : true,
        offsetIndex : this.offset,
      };

      fetchLocation(params).then((res) => {
        var content =  res.data.data.content;
        let sort = content.sort((a,b) => {return a.index - b.index})
        if(sort.length > 0) {
          this.offset = sort[sort.length - 1].index;
          let mapDataType = MapDataType.ID_LIVE_MAP_THERMAL;
          this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)
          sort.forEach((item) => {
            let mapMarkerData = new MapMarkerData();
            mapMarkerData.id = item.index;
            mapMarkerData.lng = item.lng;
            mapMarkerData.lat = item.lat;
            mapMarkerData.rotate = item.azimuth;
            mapMarkerData.thumbnail = getUrlLiveMapThumbnail(item.liveMapFileId);
            mapMarkerData.isShow = false;
            mapMarkerData.liveMapBounds = {
              leftBottom : {lat : item.latLeftBottom, lng : item.lngLeftBottom },
              rightTop : {lat : item.latRightTop, lng : item.lngRightTop }
            }
            if(this.$refs.map.onIsMarker(mapDataType, item.index ) ) {
              this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
              this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }
          })
        }
      });
    },
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_LIVE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    handleResize(){
      this.mapWidth = window.innerWidth - 322;
      this.typeChangeMapShowHeight = this.mapWidth * 0.5625;
    },
    handleTab(id) {
      this.visible = id;
    },
    backToLiveMapList(){
      this.$emit("back-list")
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
  },
};
</script>
<style lang="scss">
progress-bar {
  width: 100%;
  height: 220px;
  position: relative;
  bottom: 0;
  left: 35px;
  padding: 5px 10px;
  text-align: right;
}
input[type=range]{
  accent-color:  black;

}
input[type=range]::-webkit-slider-runnable-track {
  cursor: pointer;
  border-radius: 0.9rem;
  background: linear-gradient(180deg, rgba(255,0,0,100) 0%, rgba(255,255,0,100) 35%, rgba(0,255,32,100) 65%, rgba(0,0,255,100) 100%);
}
datalist {
  display: flex;
  /*flex-wrap: wrap;*/
  flex-direction: column;
  justify-content: space-between;
  writing-mode: bt-lr;
  /*width: 200px;*/
  margin-left: 12px;
}

option {
  padding: 0;
}

/*.progress-bar {
  width: 100%;
  height: 200px;
  position: relative;
  bottom: 0;
  left: 35px;
  !*margin: -61px 0 0;*!
  padding: 5px 10px;
  !*background-color: rgba(30, 30, 30, 0.5);*!
  text-align: right;
  -ms-transform: rotate(270deg); !* IE 9 *!
  -webkit-transform: rotate(270deg); !* Chrome, Safari, Opera *!
  transform: rotate(270deg);
}
.progress-bar > img {
  margin-top: 4px;
  float: left;
}
.progress-bar > div {
  display: contents;
}
.progress-bar > span.first {
  display:inline-block;
  padding-top: 4px;
  color : white;
  margin-right: 10px;
}
.progress-bar > span.end {
  display:inline-block;
  padding-top: 4px;
  margin-top: 4px;
  color : white;
}*/
</style>
