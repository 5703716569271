<template>
  <div class="modal team">
    <div class="box one mHeader flexB">
      <h1>{{ $t("site-view-summary-event-memo")}}</h1>
      <a @click="close"><i class="fas fa-times"></i></a>
    </div>
    <div class="box one mBody">
      <div>
        <textarea style="width: 100%; height: 300px; font-size: 1.2rem; resize: none;" v-model="memo"></textarea>
      </div>
      <div class="buttonWrap">
        <button class="point large" @click="submit">{{ $t("btn-register") }}</button>
        <button class="large margin6" @click="close">{{ $t("btn-cancel") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props : {
    memo : {
      type : String,
      default : null,
    }
  },
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit", this.memo);
    },
  },
};
</script>
