<template>
  <div class="contents liveInfo">
    <div class="new_title">
      <h1>{{ $t("site-control-menu-notice") }}</h1>
    </div>
    <div class="box one filter" style="display: flex;justify-content: flex-start;align-items: center;">
      <div class="flex">
        <p class="bold">{{ $t("search-keyword-title") }}</p>
        <select v-model="keywordType">
          <option value="all">{{ $t("search-keyword-all") }}</option>
          <option value="content">{{ $t("site-view-notice-table-contents") }}</option>
        </select>
        <input
            type="text"
            v-model="keyword"
            @keydown.enter="logSearch"
        />
      </div>
      <div class="flexC">
        <p class="bold">{{ $t("search-regdate-title") }}</p>
        <Datepicker
            v-model="startYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
        <span>~</span>
        <Datepicker
            v-model="endYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
      </div>
      <div class="buttonWrap" style="display: flex; margin-top: 0;">
          <button class="point medium" @click="logSearch" style="width: 120px; margin-left: 20px;background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 120px;border-color:#ddd;color:#969696; font-weight:400;border-radius:5px;">{{ $t("btn-reset") }}</button>
        </div>
    </div>
    <div class="notice">
      <div class="tabBox one">
        <div class="notice-table-header">
          <h2 class="bold">
          {{ $t("site-view-notice-send-list") }} ({{ $t("data-total") }} <span>{{ total }}</span
          >{{ $t("data-case-ea") }})
          </h2>
          <div class="add-btn" v-if="isUserType=='Admin'">
            <button @click="submit">공지 작성</button>
          </div>
        </div>
        <!-- <div class="none">
              <p>{{ $t("search-not-found") }}</p>
            </div> -->
        <table class="notice-table">
          <tr>
            <th>No</th>
            <th>{{ $t("site-view-notice-table-sendtime") }}</th>
            <th>{{ $t("site-view-notice-table-contents") }}</th>
            <th>{{ $t("site-view-notice-table-picture") }}</th>
            <th>{{ $t("site-view-data-remarks") }}</th>
          </tr>
          <tr v-for="(data, i) in noticeList" :key="i" class="WorkNoticeList" @click="detailNotice(data)">
            <td>{{ total - (size * currentPage + i) }}</td>
            <td :style="data.deletedDate == null ? 'color:black':'color:#aaaaaa'">
              {{moment(data.registerDate).format("YYYY.MM.DD HH:mm:ss")}}
            </td>
            <td>
              <div :style="data.deletedDate == null ? 'color:black':'color:#aaaaaa'" style="width: 50%;overflow: hidden;margin: auto;">
              {{data.deletedDate == null ? data.noticeContent : $t("notice-delete-content-text")}}
              </div>
            </td>
            <td @click="$event.cancelBubble = true" >
              <div v-if = "data.deletedDate == null && data.isImage == true"
                  style="width: auto; max-width: 6.5rem; height: 100%; max-height: 3.2rem; background-repeat: no-repeat; background-size: cover; margin: auto;"
                  :style="'background-image: url('+ getNoticeImageUrl(data) +')'">
                <div class="noticeImg" @click="popImg(data)">
                  <img src="@/assets/images/icon_svg/ic_zoom_in_line.svg" alt="" />
                </div>
              </div>
              <span v-else :style="data.deletedDate == null ? 'color:black':'color:#aaaaaa'">-</span>
                  <!--<img :src="getNoticeImageUrl(data)" alt=""
                  style="width: auto; max-width: 6.5rem; height: 100%; max-height: 3.2rem;">-->
            </td>
            <td @click="$event.cancelBubble = true" >
              <button v-if="data.deletedDate == null && isUserType == 'Admin'" class="del-btn" @click="handleDelete(data.index)">{{$t('btn-notice-delete')}}</button>
              <span v-if="data.deletedDate != null" style="color: #aaaaaa;">{{$t('btn-delete')}}</span>
            </td>
          </tr>
          <tr v-if="total == 0">
            <td colspan="5" style="text-align: center; background: white;">등록된 공지가 없습니다.</td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="size"
              @current-change="handleCurrentChange"
              :current-page="currentPage + 1"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <transition name="fade">
        <div v-if="visible == 1" class="dim on">
          <SendList />
        </div>
        <div v-if="visible == 2" class="dim on">
          <SendResult />
        </div>
      </transition> -->
    </div>
  </div>
</template>

<style>
.notice-table-header{display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px;}
.notice-table-header > h2{font-size: 1.4rem;}
.notice-table-header > h2 > span{color: #2e5ae6;}
.notice-table-header > .add-btn > button{color: #0080FF;font-size: 1.2rem;font-weight: 400;border-radius: 5px; border: 1px solid #0080FF;padding: 0 20px;background: #fff;}
.notice-table-header > .add-btn > button:hover{color: #ffffff;background: #0080FF;}
.notice-table > tr:hover{background: #f0f3f7;}
.notice-table > tr > th{text-align: center !important;}
.notice-table > tr > td{text-align: center !important;}
.del-btn{background: #fff;border: 1px solid #ddd;color: #969696;border-radius: 5px;font-weight: 400;padding: 0 20px;}
.del-btn:hover{border: 1px solid #ff5656;color: #ff5656;}
</style>

<script>
// import SendList from "@/components/pop/SendList";
// import SendResult from "@/components/pop/SendResult";
import moment from "moment";
import { baseUrl, baseApiVersion } from "@/config/env";
import { fetchEventNoticeList, deleteNotice, getNoticeImageUrl } from "@/api/event";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  // components: { SendList, SendResult },
  components: { Datepicker },
  name: "Home",
  data() {
    return {
      baseUrl: baseUrl,
      baseApiVersion : baseApiVersion,
      moment: moment,
      total: 0,
      size: 10,
      currentPage: 0,
      notice: "",
      id: "",
      noticeList: [],
      file: "",
      img: "",

      ko: ko,

      //검색 조건
      startYmd: null,
      endYmd: null,
      keywordType: "all",
      statuses: null,
      keyword: null,

      isUserType:null,
      isNoticeDetail:false,
      noticeNum:null,
    };
  },
  created() {
    this.id = this.$route.params.eventId;
    this.isUserType = localStorage.getItem("userType");
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 8 });
  },
  mounted() {
    this.getNoticeList();
  },
  methods: {
    //공지 이미지 팝업 생성(크게보기)
    popImg(data){
      var popX = window.innerWidth/2;

      var imgUrl = getNoticeImageUrl(data.accountId, data.eventId, data.index);
      var img1 = new Image();

      img1.src = imgUrl;

      img1.onload = function() {
        document.body.appendChild(img1);
        var imgWidth = img1.clientWidth;
        var imgHeight = img1.clientHeight;

        popX = popX-(imgWidth/2);

        var popSize = "width="+ imgWidth+",height="+imgHeight+",top=250,left="+popX+", scrollbars=no,";

        var imgWin = window.open(imgUrl ,"", popSize);
        imgWin.document.write("<body style='margin:0;'>")
        imgWin.document.write("<img src="+imgUrl+"/>");
      }
    },
    //공지 상세보기
    detailNotice(data){
      //this.$router.push({ name: "NoticeDetail" , query: {eventId : this.eventInfo.eventId, missionId: this.selectEl.missionId }})
      //this.$router.push({ name: "NoticeDetail", query: {index: index ,eventId : this.id}})

      if(data.deletedDate != null){
        alert("삭제된 공지입니다.")
        return
      }
      this.$router.push({ name: "NoticeDetail", params: {index: data.index ,eventId : this.id, data : data}})
    },
    getNoticeImageUrl(data){
      return getNoticeImageUrl(data.accountId, data.eventId, data.index);
    },
    //api 변경 됨 2021.11.04
    sendFile() {
      this.file = this.$refs.images.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e) => {
        this.img = e.target.result;
      };
    },
    deleteImage() {
      this.img = "";
      this.file = "";
      this.$refs.images.value = "";
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getNoticeList();
    },
    lengthLimit() {
      if (this.notice.split("").length >= 201) {
        this.notice = this.notice.slice(0, -1);
        alert(this.$t("alert-message-characters-over"));
      }
    },
    handleTab(id) {
      this.visible = id;
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.endYmd = null;
      this.startYmd = null;
      this.keyword = null;
      this.keywordType = "all";
    },

    logSearch(){
      this.currentPage = 0;
      this.getNoticeList();
    },
    getNoticeList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        eventId : this.id,
        statuses:'Used,Delete',
        keywordType: this.keywordType,
        keyword: this.keyword
      };
      params["registerDate.from"] = null;
      params["registerDate.to"] = null;
      if(this.startYmd != null && this.endYmd != null) {
        params["registerDate.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["registerDate.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchEventNoticeList(params, this.id).then((res) => {
        this.total = res.data.data.total;
        this.noticeList = res.data.data.content;
      });
     
    },
    submit() {
       this.$router.push({ name: "NoticeCreate"})
    },
    async handleDelete(index){
      let result = confirm(this.$t("notice-delete-confirm-text"));
      if (result == false) {
        return;
      }

      deleteNotice(index, this.id).then((res) => {
        if (res.data.result == 0) {
          this.getNoticeList();
        }
      });
    },
  },
};
</script>
