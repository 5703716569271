import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchWaypointLogList(params) {
  return axios.get(baseUrl + baseApiVersion + `/waypoint/log/list`, {
    headers,
    params,
  });
}

export function fetchWaypointLogInfo(logId) {
  return axios.get(baseUrl + baseApiVersion + `/waypoint/log/${logId}`, {
    headers,
  });
}