<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 50px);padding:0;">
    <div class="map" style="width: calc(100%);height: calc(65% + 20px);margin-top:50px;">
      <Map ref="map" style="width : 100%; height: 100%;" :loadMapName="loadMap" @waypoint-create="createWaypointLatLng" @waypoint-change="changeWaypointLatLng" @waypoint-add="addWaypointLatlng"/>
      <MapButtonComponent ref="buttonMap"/>
      <ul class="wp-info">
        <li>
          <span>{{ $t("mission-data-way-registrant") }}</span>
          <h4>{{ name }}</h4>
        </li>
        <li>
          <span>{{ $t("mission-data-way-dept") }}</span>
          <h4>{{ departmentName ? departmentName : "-" }}</h4>
        </li>
        <li>
          <span>{{ $t("event-data-site-phone") }}</span>
          <h4>{{ phone ? getMask(phone) : "-" }}</h4>
        </li>
      </ul>
<!--      <div class="wp-total">-->
<!--        <div class="wp-total-value" style="margin-right: 30px;">-->
<!--          <h4>-->
<!--            <img src="@/assets/images/ic_wp_total_time.png" alt="icon" />-->
<!--            <span>{{ $t("mission-data-total-time") }}</span>-->
<!--          </h4>-->
<!--          <h2><span>00:00</span></h2>  -->
<!--        </div>-->
<!--        <div class="wp-total-value">-->
<!--          <h4>-->
<!--            <img src="@/assets/images/ic_wp_total_dist.png" alt="icon" />-->
<!--            <span>{{ $t("mission-data-total-dist") }}</span>-->
<!--          </h4>-->
<!--          <h2><span>0</span>m</h2>  -->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="register" style="width: 100%;height: calc(35% - 60px);padding: 0px 30px;margin-top: 20px;display: flex;justify-content: space-between;align-items: stretch;">
      <div class="mainBox wrap-create">
        <h2 class="bold">웨이포인트 신규등록</h2>
        <div class="wrap-create-from">
          <div class="wrap-create-input">
            <h4>{{$t("mission-data-station")}}<span>*</span></h4>
            <select v-model="selectStation">
              <option :key="station.id" v-for="station in stationList" :value="station.device.stationDeviceId">{{station.device.deviceName}}</option>
            </select>
          </div>
          <div class="wrap-create-input">
            <h4>{{$t("mission-data-title")}}<span>*</span></h4>
            <input type="text" v-model="title">
          </div>
          <div class="wrap-create-input">
            <h4>{{$t("mission-data-setting-altitude")}}(m)<span>*</span></h4>
            <input type="text" v-model="altitude" placeholder="20~1000m" max="1000" min="20" @change="validateAltitude()"/>
          </div>
          <div class="wrap-create-input">
            <h4>{{$t("mission-data-setting-speed")}}(m/s)</h4>
            <input type="text" v-model="autoFlightSpeed" placeholder="2~15m/s" max="15" min="2" @change="validateSpeed()"/>
          </div>
          <div class="wrap-create-input">
            <h4>{{$t("mission-data-setting-clear")}}</h4>
            <div class="wrap-create-radio">
              <label class="radioBtn">
                <input type="radio" name="status" v-model="finishedActionType" value="RTL" checked/>RTL(Return-To-Launcher)
              </label>
              <label class="radioBtn">
                <input type="radio" name="status" v-model="finishedActionType" value="Hover"/>HOVER
              </label>
            </div>
          </div>
          <div class="wrap-create-input">
            <h4>{{$t("mission-data-setting-exit-on-rc-lost")}}</h4>
            <div class="wrap-create-radio">
              <label class="radioBtn">
                <input type="radio" name="exitOnRcLost" v-model="remoteSignalEndActionType" value="RTL" checked/>RTL(Return-To-Launcher)
              </label>
              <label class="radioBtn">
                <input type="radio" name="exitOnRcLost" v-model="remoteSignalEndActionType" value="GoAction"/>GoAction
              </label>
            </div>
          </div>
          <div class="wrap-create-input" style="margin-bottom: 0px;">
            <h4>{{ $t("mission-data-angle") }}</h4>
            <select v-model="selectGimbalType">
              <option :key="gimbalType.id" v-for="gimbalType in gimbalTypeList" :value="gimbalType.id">{{gimbalType.show}}</option>
            </select>
          </div>
        </div>
        <div class="buttonWrap" style="display: flex; margin-top: 15px; justify-content: center;margin-bottom:15px;">
          <button class="point medium" style="width: 150px;height:40px; background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;" @click="onWaypointCreate">
            {{ $t("btn-register") }}
          </button>
          <button class="medium margin6" @click="$router.go(-1)" style="width: 150px;height:40px; border-color:#ddd;color:#969696; font-weight:400;border-radius:5px;">
            {{ $t("btn-cancel") }}
          </button>
        </div>
      </div>
      <div style=" width: calc(60% - 20px);background: rgb(255, 255, 255);padding: 10px;border-radius: 5px;">
        <!--드론/멤버/포인트 테이블-->
        <div class="tableWrap one">
          <div class="tableBox" style=" height: calc(35vh - 90px); overflow-y: auto;" ><!--:style="{'max-height' : (typeChangeMapShowHeight - 225) + 'px'}">-->
            <table>
              <colgroup>
                <col style="width: 10%">
                <col style="width: 5%">
                <col style="width: 20%">
                <col style="width: 20%">
                <col style="width: 10%">
                <col style="width: 10%">
                <col style="width: 10%">
                <col style="width: 10%">
                <col style="width: 5%">
              </colgroup>
              <tr>
                <th class="fixedHeader">{{ $t("mission-data-setting-remark") }}</th>
                <th class="fixedHeader" style=" padding: 6px 15px; font-size: 1.3rem; font-weight: bold; background: #f4f4f4;">No.</th>
                <th class="fixedHeader">{{ $t("mission-data-lat") }}</th><!--위도-->
                <th class="fixedHeader">{{ $t("mission-data-long") }}</th><!--경도-->
                <th class="fixedHeader">{{ $t("mission-data-alt") }}</th><!--고도-->
                <th class="fixedHeader">{{ $t("mission-data-vel") }}</th><!--속도-->
                <th class="fixedHeader">{{ $t("mission-data-angle") }}</th><!--카메라-->
                <th class="fixedHeader">{{ $t("mission-data-action") }}</th><!--동작제어-->
                <th class="fixedHeader">{{ $t("mission-data-set") }}</th><!--설정-->
              </tr>
              <tr v-for="data in waypointLocationList" :key="data.index" @click="handleSelectEl(data)" :class="{ active: (selectData != null &&  (selectData.index ==  data.index))}">
                <td style="padding: 0px" @click="$event.cancelBubble = true">
                  <button class="small" @click="waypointLocationDelete(data)" style="border: 1px solid #ddd; color:#969696;">
                    {{ $t("btn-delete") }}
                  </button>
                </td>
                <td>{{ data.index + 1 }}</td>
                <td>{{ data.lat.toFixed(7) }}</td>
                <td>{{ data.lng.toFixed(7) }}</td>
                <td>
                  <input type="number" v-model.number="data.altitude" style="width: 60px;margin-bottom:0;" @change="validateAltitudeAtLocation(data.index, data.altitude)"/>
                </td>
                <td>
                  <input type="number" v-model.number="data.autoFlightSpeed" style="width: 60px;margin-bottom:0;" @change="validateSpeedAtLocation(data.index, data.autoFlightSpeed)"/>
                </td>
                <td>
                  <select v-model="data.gimbalType" style="width: 80px;">
                    <option :key="gimbalType.id" v-for="gimbalType in gimbalTypeList" :value="gimbalType.id">{{gimbalType.show}}</option>
                  </select>
                </td>
                <!-- 추가 테이블 start -->
                <td>
                  <span class="action-data">{{ data.actions.length }}</span>
                </td>
                <td><button @click="openModal(data.index)" style="border: 0;"><img src="@/assets/images/ic_mission_setting.png" alt="icon" style="width: 15px;" /></button></td>
                <!-- 추가 테이블 end -->
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isAllowModalShow" class="modal-mask">
      <AutoFlightLiabilityModal
          @isShowModal="isShowAllowModal"
          @sendAllow="saveWayPoint"
      >
      </AutoFlightLiabilityModal>
    </div>

    <div v-show="isActionModalShow" class="modal-mask">
        <MissionActionModal
            ref = "MissionActionModal"
            @isShowModal="isShowActionModal"
            @saveActionItem="saveActionItem"
      >
        </MissionActionModal>
    </div>
  </div>
</template>
<style>
.title{display: none;}
.radioBtn{
  width: auto;
  font-size: 1.2rem;
}
.radioBtn:nth-child(1){
  margin-right: 20px;
}
.wrap-create{border-radius: 5px;padding: 15px;width: 40%;}
.wrap-create > h2{font-size: 1.4rem;margin-bottom: 15px;}

.wp-info{display: flex;margin: 0;position: absolute;top: 15px;left: 30px;align-items: center;background: #ffffff;padding: 15px;border-radius: 5px;}
.wp-info > li{display: flex;justify-content: space-between;align-items: center;border: 0;padding: 0;margin: 0;margin-right: 30px;}
.wp-info > li > span{background: #eee;width: 80px;text-align: center;padding: 3px;font-size: 1.2rem;border-radius: 10px;color: #969696;}
.wp-info > li > h4{font-size: 1.2rem;font-weight: 500;color: #454545;margin-left: 10px;}

.wrap-create-from{padding: 15px 0;height: calc(90% - 60px);overflow-y: scroll;border-top: 1px solid #ddd;border-bottom: 1px solid #ddd;}
.wrap-create-input{width: 100%;margin-bottom: 10px;display: flex;align-items: center;justify-content: space-between;padding-right: 10px;}
.wrap-create-input > h4{font-size: 1.2rem;color: #969696;font-weight: 500;margin-bottom: 5px;}
.wrap-create-input > h4 > span{color: #f00;margin-left: 3px;}
.wrap-create-input > input{width: 80%;border-radius: 5px;height: 30px;margin-bottom: 0;}
.wrap-create-input > input::placeholder{color: #cecece;}
.wrap-create-input > label{width: 80%;display: block;font-size: 1.2rem;margin-bottom: 15px;}
.wrap-create-input > label:nth-child(2){padding-top: 10px;}
.wrap-create-input > label:nth-child(3){margin-left: 0;margin-bottom: 25px;}
.wrap-create-input > select{width: 80%;border-radius: 5px;height: 30px;}
.missionActive{background: #0080FF;}
.mission-sub > li:nth-child(2) > a{background: #0080FF;border-radius: 10px;}
.el-menu > li:nth-child(1){color: #fff !important;}

.wrap-create-radio{display: flex;justify-content: flex-start;width: 80%;}

/* modal start */
.modal-mask {position: fixed;z-index: 9998;top: 0;left: 0;width: 100%;height: 100%; background-color: rgba(0, 0, 0, 0.5);display: flex;align-items: center;justify-content: center;}

.modal-wrapper {width: auto;}
.modal-container {background-color: white;padding: 20px;border-radius: 5px;}

.modal-title {display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #ddd;margin-bottom: 15px;padding-bottom: 10px;}

.modal-title>h3 {font-size: 1.6rem;}
.modal-title>button {border: 0;}

.modal-btn {margin-top: 15px;margin-bottom: 10px;display: flex;justify-content: center;}
.modal-btn>button {margin: 0 5px;width: 120px;height: 35px;border: 1px solid #ddd;border-radius: 5px;color: #969696;font-size: 1.4rem;}
.modal-btn>button.btn-save {background: #0080ff;color: #fff;border: 1px solid #0080ff;}

.camera-data-info{display: flex;align-items: center;justify-content: space-between;padding-bottom: 5px;border-bottom: 1px solid #ddd;}
.camera-data-info > h3{font-size: 1.4rem;font-weight: 500;color: #969696;}
.camera-data-info > h3 > span{color: #0080ff;}
.camera-data-info > button{border: 0;display: flex;align-items: center;font-size: 1.4rem;color: #969696;}
.camera-data-info > button > img{margin-right: 5px;padding-top: 2px;}

.camera-set{display: flex;border-bottom: 1px solid #ddd;height: 350px;width: 715px;}
.set-list{height: 350px;max-height: 350px;overflow-y: auto;padding: 15px 15px 15px 0px;border-right: 1px solid #ddd;}
.set-list-box{border: 1px solid #ddd;border-radius: 5px;padding: 10px;margin-bottom: 10px;}
.set-list-box.active {
  border-color: #007bff;
}
.set-list-value{display: flex;align-items: center;}
.set-list-value > h4{font-size: 1.4rem;}
.set-list-value > select{border: 0;margin-left: 5px;font-size: 1.4rem;font-weight: 500;}
.set-list-box > h5{font-size: 1.2rem;color: #969696;font-weight: 300;padding-left: 28px;margin-top: 5px;}

.set-content{width: 500px;padding: 15px;}
.set-content-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px;}
.set-content-title > h3{font-size: 1.4rem;}
.set-content-title > button{border: 0;font-size: 1.4rem;display: flex;align-items: center;color: #969696;}
.set-content-title > button > img{margin-right: 5px;padding-top: 2px;}

.set-toggle{display: flex;justify-content: space-between;align-items: center;padding-bottom: 15px;border-bottom: 1px solid #ddd;}
.set-toggle > h4{font-size: 1.4rem;font-weight: 500;}

.set-sub-toggle{padding: 15px 0;}
.set-sub-toggle > li{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.set-sub-toggle > li > h4{font-size: 1.4rem;font-weight: 500;}
/* 토글 슬라이더 */
.switch {position: relative; display: inline-block;width: 40px;height: 20px;}
.switch input { opacity: 0; width: 0;height: 0;}
.slider {position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color: #ccc;transition: .4s;}
.slider:before {position: absolute;content: "";height: 18px;width: 18px;left: 2px;bottom: 1px;background-color: white;transition: .4s;}
input:checked + .slider {background-color: #2196F3;}
input:focus + .slider {box-shadow: 0 0 1px #2196F3;}
input:checked + .slider:before {transform: translateX(18px);}
.slider.round {border-radius: 34px;}
.slider.round:before {border-radius: 50%;}
.switch-title{margin-left: 30px;display: inline-block;margin-right: 5px;border-radius: 10px;color: #c9c9c9;font-size: 1.2rem;}

.action-mark{display: inline-block; width: 10px;height: 10px;border-radius: 10px;background: #bbbbbb;margin-right: 5px;}
.action-mark.mark-on{background: #0ccd49;}
.message-warning {
  color: #969696;
  font-size: 1.2rem;
  margin-top: 10px;
  padding: 10px;
  background: #eee;
  border-radius: 5px;
  text-align: center;
}
/* modal end */
.wp-total{position: absolute;top: 15px;left: 50%;transform: translate(-50%, 0%);background: #ffffff;display: flex;border-radius: 3px;padding: 16px;}
.wp-total-value{display: flex;align-items: center;}
.wp-total-value > h4{display: flex;align-items: center;}
.wp-total-value > h4 > img{height: 16px;margin-right: 5px;}
.wp-total-value > h4 > span{color: #454545;font-size: 1.4rem;font-weight: 400;}
.wp-total-value > h2{margin-bottom: 0;font-size: 1.6rem;margin-left: 20px;}
.wp-total-value > h2 > span{font-size: 1.6rem;font-weight: 500;}



</style>
<script>

import {mapState} from "vuex";
import moment from "moment";
import Map from "@/components/map/Map";
import AutoFlightLiabilityModal from "@/components/mission/modal/AutoFlightLiabilityModal"

import {fetchEvent} from "@/api/event";
import {format} from "@/mixins/format";
import {getWaypointGimbalPitchList} from "@/api/missionWaypoint";
import {fetchMissionCreate,createPanoShotAction,createTakePhotoAction} from "@/api/mission";
createPanoShotAction,createTakePhotoAction
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MissionActionModal from "@/components/mission/action/Modal.vue";

import {getDefaultMapZoomLevel} from "@/util/common";
import { fetchStationDeviceDockList } from "@/api/station/stationDevice";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

export default {
  props: ["eventId", "waypointId"],
  mixins: [format],
  components: {Map, MapButtonComponent,AutoFlightLiabilityModal,MissionActionModal},
  computed: {
    ...mapState({
      name: state => state.store.name,
      accountId: state => state.store.accountId,
      loginId: state => state.store.loginId,
      ownerWork: state => state.store.ownerWork,
      phone: state => state.store.phone,
      departmentName: state => state.store.departmentName,
      currentLang: state => state.store.currentLang,
      selectedSetting() {
      return this.selectedIndex !== null ? this.cameraSettings[this.selectedIndex] : {};
      },
    }),
  },
  data() {
    return {
      moment: moment,
      missionId: "",
      eventInfo: {},
      mapHeight: 0,
      title: null,
      loadMap : 'kakao',
      autoFlightSpeed: null,
      altitude: null,
      finishedActionType: "RTL",
      remoteSignalEndActionType : "RTL", //GoAction

      waypointLocationList: [],
      waypointLocationTotal: 0,

      devPositionHeight: 122,
      typeChangeMapShowHeight: 0,
      selectEl: null,
      selectData: null,
      selectGimbalType : "None",
      gimbalTypeList: getWaypointGimbalPitchList(),
      stationList : null,
      selectStation : null,
      showCameraSetModal: false,
      cameraSettings: [],
      selectedIndex: null,
      Setting: {},
      savedCameraSettings: 0,

      autoSpeedRange : {
        max : 15,
        min : 2
      },
      altitudeRange : {
        max : 1000,
        min : 20
      },
      isActionModalShow : false,
      isAllowModalShow : false,
    };
  },
  mounted() {
    this.getEvent();
    this.getStationList();
  },
  created() {
    this.mapHeight = window.innerHeight - this.devPositionHeight;
    this.typeChangeMapShowHeight = this.mapHeight * 0.6485;
    window.addEventListener('resize', this.handleResize);

    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 10 });
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    selectStation(newVal , oldVal){
      console.log("stationSelect")
      console.log(`${oldVal}`)
      console.log(`${newVal}`)
      this.setStationMapMarker(newVal, oldVal)
    },
  },
  methods: {
    initMapData(value) {
      if (value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function () {
        this.setMapSetting();
        this.setMapEventCenter();
      })
    },
    getEvent() {
      if (this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.memo = this.eventInfo.memo;
          if (this.eventInfo.status == "Close") {
            const elapsedMSec = this.eventInfo.closeDate.getTime() - this.eventInfo.openDate.getTime()
            this.elapsedDay = elapsedMSec / 1000 / 60 / 60 / 24;
          }
          this.initMapData(this.currentLang);
        }
      });
    },
    setMapEventCenter() {
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if (this.eventInfo.lng == null || this.eventInfo.lng == "" && this.eventInfo.lat == null || this.eventInfo.lat == "") {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      } else {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      }
    },
    setMapSetting() {
      this.$refs.map.isShowLine = true;
      this.$refs.map.onSetIsPolylineClick();
      this.$refs.map.onSetIsMarkerMove();
      this.$refs.map.onSetIsWindowInfo(MapDataType.ID_WAYPOINT);
      this.$refs.buttonMap.showMapTypeChange();
    },
    handleSelectEl(data) {
      var id = data.index;
      if (this.selectEl == id) {
        this.selectEl = null;
        this.selectData = null;
      } else {
        this.selectEl = id;
        this.selectData = data;
      }
      if (this.selectData != null) {
        this.$refs.map.onLngLatToMoveCenter(this.selectData.lng, this.selectData.lat)
      }
    },

    /**
     * 라인에 마커를 추가한 경우 (n번, n+1번 사이)
     * @param index
     * @param latlng
     */
    addWaypointLatlng(index, latlng) {
      let locationData = {
        index: index,
        altitude: this.altitude,
        autoFlightSpeed: this.autoFlightSpeed,
        lat: latlng.lat,
        lng: latlng.lng,
        actions: []
      }
      this.waypointLocationList.splice(index, 0, locationData);

      for (let i = 0; i < this.waypointLocationList.length; i++) {
        this.waypointLocationList[i].index = i;
      }

      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = index;
      mapMarkerData.lng = locationData.lng;
      mapMarkerData.lat = locationData.lat;
      mapMarkerData.rotate = 0;
      mapMarkerData.name = index;
      let mapDataType = MapDataType.ID_WAYPOINT;
      this.$refs.map.onCreateMarkerIndex(mapDataType, mapMarkerData, index);
      this.$refs.map.onWaypointMarkerIdSort();

      let slice = this.waypointLocationList.slice(index - 1, Number(index) + 1);
      let mapLineData = new MapLineData();
      mapLineData.id = index;
      mapLineData.locationGroup = slice;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      this.$refs.map.onCreateLineIndex(MapDataType.ID_WAYPOINT, mapLineData, index);
      this.$refs.map.onWaypointLineIdSort();
    },

    changeWaypointLatLng(index, latlng) {
      this.waypointLocationList[index].lat = latlng.lat;
      this.waypointLocationList[index].lng = latlng.lng;
    },

    /**
     * 그냥 마커 추가한경우
     * @param index
     * @param latlng
     */
    createWaypointLatLng(latlng) {
      let locationData = {
        index: this.waypointLocationList.length,
        altitude: this.altitude,
        autoFlightSpeed: this.autoFlightSpeed,
        lat: latlng.lat,
        lng: latlng.lng,
        gimbalType: this.selectGimbalType,
        actions: []
      }
      this.waypointLocationList.push(locationData);
      let mapDataType = MapDataType.ID_WAYPOINT

      let index = this.waypointLocationList.length.toString();
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = index;
      mapMarkerData.lng = locationData.lng;
      mapMarkerData.lat = locationData.lat;
      mapMarkerData.rotate = 0;
      mapMarkerData.name = index;

      if (this.$refs.map.onIsMarker(mapDataType, index)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
      if (this.waypointLocationList.length > 1) {
        let slice = this.waypointLocationList.slice(this.waypointLocationList.length - 2, this.waypointLocationList.length);
        this.createMapLine(this.waypointLocationList.length - 1, slice);
      }
    },

    createMapLine(id, locationGroup) {
      let mapDataType = MapDataType.ID_WAYPOINT
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineReplaceAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },

    waypointLocationDelete(data) {
      let result = confirm("웨이포인트 번호 " + (data.index + 1) + "를 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }

      this.waypointLocationList.splice(data.index, 1);
      for (var i = 0; i < this.waypointLocationList.length; i++) {
        this.waypointLocationList[i].index = i;
      }
      this.$refs.map.onDeleteMarkerIndex(MapDataType.ID_WAYPOINT, data.index);
      this.$refs.map.onWaypointMarkerIdSort();
      this.$refs.map.onWaypointLineIdSort();


    },

    onWaypointCreate() {
      if (this.title == null || this.title.trim() == "") {
        alert(this.$t("alert-message-check-mission-title"));
        return;
      }

      if (this.altitude == null || this.altitude.toString().trim() == "") {
        alert(this.$t("alert-message-check-mission-height"));
        return;
      }

      if (this.autoFlightSpeed == null || this.autoFlightSpeed.toString().trim() == "") {
        alert(this.$t("alert-message-check-mission-speed"));
        return;
      }

      if (this.waypointLocationList == null || this.waypointLocationList.length == 0) {
        alert(this.$t("alert-message-check-mission-location"));
        return;
      }
      if (!this.checkAutoFlightSpeed(this.autoFlightSpeed)) {
        alert(this.$t("alert-message-check-mission-speed-value"));
        return
      }
      if (!this.checkAltitude(this.altitude)) {
        alert(this.$t("alert-message-check-mission-altitude-value"));
        return
      }
      var isNotEmptySpeed = true
      var isNotEmptyAltitude = true
      this.waypointLocationList.forEach(item => {
        if (!this.checkAutoFlightSpeed(item.autoFlightSpeed)) isNotEmptySpeed = false
        if (!this.checkAltitude(item.altitude)) isNotEmptyAltitude = false
      })
      if (!isNotEmptySpeed) {
        alert(this.$t("alert-message-check-mission-speed-value"));
        return
      }
      if (!isNotEmptyAltitude) {
        alert(this.$t("alert-message-check-mission-altitude-value"));
        return
      }
      this.isShowAllowModal(true)
    },
    saveWayPoint(allow) {
      if (!allow) return

      var data = {
        autoFlightSpeed: this.autoFlightSpeed,
        altitude: this.altitude,
        eventId: this.eventId,
        title: this.title,
        finishedActionType: this.finishedActionType,
        locations: this.waypointLocationList,
        missionRefType: "Drone",
        missionType: "WayPoint",
        refId: null, //스테이션 ID
        missionAutoFlightLiabilityAllow: allow,
        remoteSignalEndActionType : this.remoteSignalEndActionType,
      }
      if (this.selectStation != null) {
        data.missionRefType = "Station"
        data.refId = this.selectStation
      }

      fetchMissionCreate(data).then((res) => {
        if (res.data.result == 0) {
          alert("웨이포인트 등록 성공했습니다.");
          this.$emit("waypoint-create-success")
          // this.$router.go(-1);
          this.$router.push("Mission");
        } else {
          if (res.data.message == "waypoint title is already") {
            alert("웨이포인트 경로 제목이 중복 되었습니다.")
          }
        }
      })
    },

    checkAutoFlightSpeed(autoFlightSpeed) {
      if (autoFlightSpeed == null || autoFlightSpeed.toString().trim() == "") {
        return false
      }
      var temp = parseInt(autoFlightSpeed)
      if (1 < temp && temp < 16) return true

      return false
    },
    checkAltitude(altitude) {
      if (altitude == null || altitude.toString().trim() == "") {
        return false
      }
      var temp = parseInt(altitude)
      if (19 < temp && temp < 1001) return true

      return false
    },
    //페이지 리사이즈
    handleResize() {
      this.mapHeight = window.innerHeight - this.devPositionHeight;
      this.typeChangeMapShowHeight = this.mapHeight * 0.5625;
    },
    backToMissionList() {
      this.$emit("back-list")
    },
    validateAltitude() {
      const min = this.altitudeRange.min;
      const max = this.altitudeRange.max;
      const value = parseFloat(this.altitude);

      if (isNaN(value)) {
        this.altitudeWarning = '숫자를 입력하세요.';
        this.altitude = '';
      } else if (value < min) {
        this.altitudeWarning = `고도는 최소 ${min}m이어야 합니다.`;
        this.altitude = min;
      } else if (value > max) {
        this.altitudeWarning = `고도는 최대 ${max}m이어야 합니다.`;
        this.altitude = max;
      } else {
        this.altitudeWarning = '';
      }
    },
    validateSpeed() {
      const min = this.autoSpeedRange.min;
      const max = this.autoSpeedRange.max;
      const value = parseFloat(this.autoFlightSpeed);

      if (isNaN(value)) {
        this.speedWarning = '숫자를 입력하세요.';
        this.autoFlightSpeed = '';
      } else if (value < min) {
        this.speedWarning = `속도는 최소 ${min}m/s이어야 합니다.`;
        this.autoFlightSpeed = min;
      } else if (value > max) {
        this.speedWarning = `속도는 최대 ${max}m/s이어야 합니다.`;
        this.autoFlightSpeed = max;
      } else {
        this.speedWarning = '';
      }
    },
    /**
     * locationd에 담긴 speed의 범위값 체크
     * */
    validateSpeedAtLocation(index, speed) {
      const min = this.autoSpeedRange.min;
      const max = this.autoSpeedRange.max;
      const value = parseFloat(speed);
      if (isNaN(value)) {
        this.speedWarning = '숫자를 입력하세요.';
        this.waypointLocationList[index].autoFlightSpeed = '';
      } else if (value < min) {
        this.speedWarning = `속도는 최소 ${min}m/s이어야 합니다.`;
        this.waypointLocationList[index].autoFlightSpeed = min;
      } else if (value > max) {
        this.speedWarning = `속도는 최대 ${max}m/s이어야 합니다.`;
        this.waypointLocationList[index].autoFlightSpeed = max;
      } else {
        this.speedWarning = '';
      }
    },
    /**
     * locationd에 담긴 altitude 범위값 체크
     * */
    validateAltitudeAtLocation(index, altitude) {
      const min = this.altitudeRange.min;
      const max = this.altitudeRange.max;
      const value = parseFloat(altitude);

      if (isNaN(value)) {
        this.altitudeWarning = '숫자를 입력하세요.';
        this.waypointLocationList[index].altitude = '';
      } else if (value < min) {
        this.altitudeWarning = `고도는 최소 ${min}m이어야 합니다.`;
        this.waypointLocationList[index].altitude = min;
      } else if (value > max) {
        this.altitudeWarning = `고도는 최대 ${max}m이어야 합니다.`;
        this.waypointLocationList[index].altitude = max;
      } else {
        this.altitudeWarning = '';
      }
    },
    getStationList() {
      /**
       * 화면에 보여주는건 deviceName
       * 보낼때는 stationDeviceId를 전달
       */
      const params = {
        eventId: this.eventId
      }
      var tempList = []
      fetchStationDeviceDockList(params).then((res) => {
        console.log(res)
        if (res.status == 200) {
          tempList = res.data.data.content
          // .sort((a, b) => (a?.managerData?.tag) - (b?.managerData?.tag))
          // console.log(this.station)
          console.log(res.data.data.content)

        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        var data = {
          "device": {stationDeviceId: null, deviceName: "None"}
        }
        tempList.unshift(data)
        this.stationList = tempList
        this.selectStation = data.device.stationDeviceId
      })
      ;

      // 응답오고 지도상에 표시

    },
    setStationMapMarker(id, oldId) {
      const mapDataType = MapDataType.ID_SCHEDULE_STATION
      // const index = Number(item.device.managerData.stationDeviceId)
      const mapMarkerData = new MapMarkerData();


      if (id === null || id !== oldId) {
        this.$refs.map.onDeleteMarkerList(mapDataType)
      }
      if (id === null) return;

      let stationDeviceIndex = this.stationList.findIndex(item => item.device.stationDeviceId == id);
      if (stationDeviceIndex != -1) {
        let stationDeviceInfo = this.stationList[stationDeviceIndex];
        mapMarkerData.id = stationDeviceInfo.device.stationDeviceId;
        mapMarkerData.name = stationDeviceInfo.device.deviceName;
        mapMarkerData.isMain = true
        let lng = stationDeviceInfo.device.managerData.lng
        let lat = stationDeviceInfo.device.managerData.lat
        if (lng == null || lat == null) {
          return;
        }
        mapMarkerData.lng = stationDeviceInfo.device.managerData.lng;
        mapMarkerData.lat = stationDeviceInfo.device.managerData.lat;
        this.$refs.map.onLngLatToMoveCenter(lng, lat)

        if (this.$refs.map.onIsMarker(mapDataType, stationDeviceInfo.device.stationDeviceId)) {
          this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
        } else {
          this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
        }
      }
    },
    isShowAllowModal(isShow) {
      this.isAllowModalShow = isShow
    },
    isShowActionModal(isShow) {
      this.isActionModalShow = isShow
    },

    /**
     * 액션 저장
     * @param index 웨이포인트마커 index
     * @param item 마커에 들어있는 액션 list
     */
    saveActionItem(index, actionList) {
      const actions = []

      actionList.forEach((action) => {
        const actionObj = {}
        actionObj.index = action.index
        actionObj.missionLocationIndex = index
        actionObj.actionType = action.actionType
        actionObj.actionElementList = action.actionElementList
        actions.push(actionObj)
      })

      this.waypointLocationList[index].actions = actions
      console.log(this.waypointLocationList)
    },

    openModal(index) {
      this.$refs.MissionActionModal.openModal(index, this.waypointLocationList[index].actions)
    },

    closeCameraSet() {
      this.showCameraSetModal = false;
    },

    deleteSetting(index) {
      this.cameraSettings.splice(index, 1);
      this.selectedIndex = null;
    },
    updateSelectedSetting(setting) {
      this.selectedIndex = this.cameraSettings.indexOf(setting);
    },
  }
  // 삭제는 SDFile delete fileId 사용
};
</script>
<style scoped="scoped">
.fixedHeader {
  position: sticky;
  top: 0;
}
/* 모달 스타일 */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
