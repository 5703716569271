<template>
  <div class="contents liveInfo">
    <div class="tabBox recode" >
      <!--    이동 기록 -->
      <div class="contents">
        <div class="one box">
          <div class="boxCol2-2 flexB">

            <div class="tableWrap box" style="width: calc(60% - 2px); height: 100%; min-height: 566px; border: none;">
              <h2 class="bold" style="display: inline-block">
                {{ $t("missionvod-data-mission-list") }}
              </h2>
              <p style="display: inline-block; margin-left: 30px;">
                {{ $t("data-total") }} {{ waypointTotal }} {{$t("data-default-ea")}}
              </p>
              <div class="tableBox">
                <table>
                  <tr>
                    <th>No</th>
                    <th> {{ $t("missionvod-data-mission-name") }}</th>
                    <th> {{ $t("missionvod-data-mission-dept") }}</th>
                    <th> {{ $t("missionvod-data-mission-register") }}</th>
                    <th> {{ $t("missionvod-data-mission-regdate") }}</th>
                  </tr>
                  <tr v-if="waypointTotal == 0">
                    <td colspan="5" style="text-align: center;">
                      {{ $t("search-not-found") }}
                    </td>
                  </tr>
                  <tr v-for="(data, i) in waypointList" :key="i" @click="handleWaypoint(data)" :class="{ active: waypointId == data.waypointId}">
                    <td>{{ waypointTotal - (waypointSize * waypointCurrentPage + i) }}</td>
                    <td>{{ data.title }}</td>
                    <td>{{ data.userDepartmentName }}</td>
                    <td>{{ data.userName }}</td>
                    <td>{{ dateFormat(data.registerDate) }}</td>
                  </tr>
                </table>
              </div>
              <div class="pagination" style="margin-top: 15px;">
                <el-pagination
                    small
                    layout="prev, pager, next"
                    :total="waypointTotal"
                    :page-size="waypointSize"
                    @current-change="handleWaypointCurrentChange"
                    :current-page="waypointCurrentPage + 1"
                >
                </el-pagination>
              </div>
            </div>

            <div class="box" style="width: calc(40% - 2px); height: 100%; border: none; padding-bottom: 0px;">
              <div class="mapWrap" style="width: 100%">
                <div class="map" style="min-height: 530px; width: 100%;">
                  <Map style="width: 100%; height: 100%; position: absolute;" ref="map" isRightLngLat="true" :loadMapName="loadMap"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="one box">
          <div class="boxCol2-2 flexB">
            <div class="tableWrap box" style="width: calc(60% - 2px); height: 100%; min-height: 375px; max-height: 375px; border: none;">
              <h2 class="bold" style="display: inline-block">
                {{ $t("missionvod-data-mission-vod-list") }}
              </h2>
              <p style="display: inline-block; margin-left: 30px;">
                {{ $t("data-total") }} {{ waypointContentsTotal }} {{$t("data-default-ea")}}
              </p>
              <div class="tableBox" style="overflow-y: auto; height: 100%; min-height: 355px; max-height: 355px;">
                <table>
                  <colgroup>
                    <col style="width: 5%">
                    <col style="width: 25%">
                    <col style="width: 30%">
                    <col style="width: 25%">
                    <col style="width: 15%">
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th> {{ $t("missionvod-data-mission-drone-name") }}</th>
                    <th> {{ $t("missionvod-data-mission-dept") }}</th>
                    <th> {{ $t("missionvod-data-mission-operation") }}</th>
                    <th> {{ $t("missionvod-data-mission-regdate") }}</th>
                  </tr>
                  <template v-if="waypointContentsTotal == 0">
                    <tr>
                      <td colspan="5">영상이 없습니다.</td>
                    </tr>
                  </template>
                  <template v-if="waypointContentsTotal != 0">
                    <tr v-for="(data, i) in waypointContentsList" :key="i" @click="handleContents(data)" :class="{ active: contentsId == data.contentsId}">
                      <td>{{ waypointContentsTotal - (i) }}</td>
                      <td>{{ data.deviceName }}</td>
                      <td>{{ data.userName }}</td>
                      <td>{{ data.userDepartmentName }}</td>
                      <td>{{ moment(data.registerDate).format("YYYY.MM.DD HH:mm:ss") }}</td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>

            <div class="box" style="width: calc(40% - 2px); height: 100%; min-height: 355px; max-height: 355px; border: none;">
              <div class="mapWrap" style="width: 100%; margin-top: 15px">
                <div>
                  <videoPlayer :src="viewSrc" @onTimeUpdate="onTimeUpdate"></videoPlayer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.progress-bar {
  width: 100%;
  height: 50px;
  position: relative;
  bottom: 0;
  margin: -61px 0 0;
  padding: 3.8px 19.8px 3px 18.6px;
  background-color: rgba(30, 30, 30, 0.5);
  text-align: right;
}
.progress-bar > img {
  margin-top: 4px;
  float: left;
}
.progress-bar > div {
  display: contents;
}
.progress-bar > span.first {
  display:inline-block;
  padding-top: 4px;
  color : white;
  margin-right: 10px;
}
.progress-bar > span.end {
  display:inline-block;
  padding-top: 4px;
  margin-top: 4px;
  color : white;
}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import videoPlayer from "@/components/video/videoPlayer";
import { fetchVodSrc } from "@/api/file";

import { fetchEvent } from "@/api/event";
import { fetchWaypointList } from "@/api/missionWaypoint";
import { fetchWaypointLocationList } from "@/api/missionWaypointLocation";
import { fetchWaypointLogList } from "@/api/missionWaypointLog";

import { format } from "@/mixins/format";
import { fetchVodUrlList } from "@/api/server";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import { fetchLocation } from "@/api/location";
export default {
  name: "Home",
  components: { Map, videoPlayer },
  mixins: [format],
  props: {
    eventId: {
      type: String,
      default: "",
    },
  },
  watch: {
    currentLang(value){
      this.initMapData(value);
    },
    progressbarValue : function(value){
      this.$refs.map.onPlayUpdatePosition(value);
    },
    isMapPlay : function(value){
      if(value) {
        this.interval = setInterval(() => {
          if(this.progressbarValue == this.progressbarTotal) {
            clearInterval(this.interval);
            this.interval = null;
            return;
          }
          this.progressbarValue =  Number(this.progressbarValue) + 1;
        }, 1000);
      } else {
        if(this.interval != null) {
          clearInterval(this.interval);
        }
      }
    },
  },
  data() {
    return {
      moment: moment,
      isLoading:false,
      visiblePop: false,
      loadMap : 'kakao',
      eventInfo: null,

      waypointLocationList : [],
      waypointLocationTotal : 0,

      waypointId : null,
      waypointList: [],
      waypointTotal: 0,
      waypointSize: 10,
      waypointCurrentPage: 0,
      keywordType: "all",
      keyword: null,

      waypointContentsList: [],
      waypointContentsTotal: 0,
      waypointContentsCurrentPage: 0,

      locationList : [],
      contentsId : null,
      viewSrc : null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 4 });
  },
  async mounted() {
    this.getVodEndpoint();
    this.getEvent();
    this.getWaypointList();
    this.$refs.map.isShowLine = true;
    this.initMapData(this.currentLang);
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  methods: {
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function () {
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        this.$refs.map.onShowButtonTypeChange();
        if(this.eventInfo == null) {
          return
        }
        if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
          this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
        } else {
          this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        }
      })
    },
    dateFormat(date) {
      return moment(date).format("YYYY.MM.DD HH:mm:ss");
    },
    handleContents(data) {
      this.viewSrc = null;
      if(this.contentsId == data.contentsId) {
        this.contentsId = null;
        return;
      }
      this.contentsId = data.contentsId;
      this.getVodUrl();
      this.getLocation(data);
    },
    async getVodEndpoint() {
      await fetchVodUrlList().then((res) => {
        this.vodUrl = res.data.data.properties.vodStreamUrl + "/" + res.data.data.properties.application;
      });
    },
    async getVodUrl() {
      await fetchVodSrc(this.contentsId).then((res) => {
        this.viewSrc = this.vodUrl + res.data.data.streamUri;
      });
    },
    handleContentsCurrentChange(val) {
      this.waypointContentsCurrentPage = val - 1;
      this.getWaypointLogList();
    },
    async getWaypointLogList() {
      let params = {
        pageNumber: this.waypointContentsCurrentPage,
        waypointId : this.waypointId,
        isLiveMap : false,
        isEndTime : true,
        isStartTime : true
      };

      await fetchWaypointLogList(params).then((res) => {
        this.waypointContentsTotal = res.data.data.totalElements;
        this.waypointContentsList = res.data.data.content;
      });
    },
    handleWaypoint(data) {
      this.$refs.map.onClearMapData();
      this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
      this.waypointContentsList = [];
      this.waypointContentsTotal = 0;
      this.locationList = [];
      this.contentsId = null;
      this.viewSrc = null;
      this.deleteMapMarker("way-point-position");

      if(this.waypointId == data.waypointId) {
        this.waypointId = null;
        return;
      }
      this.waypointId = data.waypointId;
      this.getWaypointLocationList();
      this.getWaypointLogList();
    },
    handleWaypointCurrentChange(val) {
      this.waypointCurrentPage = val - 1;
      this.getWaypointList();
    },
    getWaypointList() {
      var params = {
        eventId : this.eventId,
        page_page: this.waypointCurrentPage,
        page_size : this.waypointSize,
        keywordType: this.keywordType,
        keyword: this.keyword,
      };

      if(this.startYmd != null && this.endYmd != null) {
        params["dateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }

      fetchWaypointList(params).then((res) => {
        if(res.data.result == 0) {
          this.waypointTotal = res.data.data.totalElements;
          this.waypointList = res.data.data.content;
        } else {
          this.waypointTotal = 0;
          this.waypointList = [];
        }
      });
    },
    onTimeUpdate(sec) {
      if(this.locationList.length > 0) {
        var line = this.locationList;
        var first = line[0];
        var isFindIndex = (element) => {
          var firstTimeDate = new Date(moment(first.time));
          var filterTimeDate = new Date(moment(element.time));

          var firstTime = firstTimeDate.getTime() / 1000;
          var filterTime = filterTimeDate.getTime() / 1000;
          var findTime = filterTime - firstTime;
          return sec <= findTime;
        };

        var findIndex = line.findIndex(isFindIndex);

        if (findIndex != -1) {
          if (findIndex > line.length) {
            findIndex = line.length;
          }
        } else {
          findIndex = 0;
        }

        let findLocation = this.locationList[findIndex];
        this.createMapMarker("way-point-position", findLocation);
      }
    },
    getLocation(data){
      if(data.deviceLogId == null) {
        return;
      }

      let params = {
        refId: data.deviceLogId,
        eventId : this.eventId,
        'localDateRange.from' : data.startDate,
        'localDateRange.to' : data.endDate,
      };

      if(data.startDate != undefined && data.endDate != undefined) {
        params['localDateRange.from'] = moment(data.startDate).format("YYYY-MM-DDTHH:mm:ss");
        params['localDateRange.to'] = moment(data.endDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      fetchLocation(params).then((res) => {
        if(res.data.result == 0) {
          if(res.data.data.content.length > 0) {
            res.data.data.content.sort(function(a, b) {
              return a.index - b.index;
            })
            this.locationList = res.data.data.content;
            this.createMapMarker("way-point-position",this.locationList[0]);
          }
        }
      });
    },
    getWaypointLocationList(){
      let params = {
        eventId : this.eventId,
        waypointId : this.waypointId
      };

      fetchWaypointLocationList(params).then((res) => {
        if (res.data.result == 0) {
          this.waypointLocationTotal = res.data.data.total;
          this.waypointLocationList = res.data.data.content;

          let sort = this.waypointLocationList.sort((a,b) => {return a.index - b.index})
          let mapDataType = MapDataType.ID_WAYPOINT
          if( sort.length > 0) {
            this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)

            sort.forEach( (item ) => {
              let index = item.index + 1
              let mapMarkerData = new MapMarkerData();
              mapMarkerData.id = index;
              mapMarkerData.lng = item.lng;
              mapMarkerData.lat = item.lat;
              mapMarkerData.rotate = item.azimuth;
              mapMarkerData.name = index;

              if(this.$refs.map.onIsMarker(mapDataType, index ) ) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
              } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
              }
            })
            this.createMapLine("way-point", sort);
          } else {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          }
        }
      })
    },
    deleteMapMarker(id){
      let mapDataType = MapDataType.ID_DRONE
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      if(this.$refs.map.onIsMarker(mapDataType, id ) ) {
        this.$refs.map.onDeleteMarkerAt(mapDataType, mapMarkerData);
      }
    },
    createMapMarker(id, item){
      let mapDataType = MapDataType.ID_DRONE
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = item.lng;
      mapMarkerData.lat = item.lat;
      mapMarkerData.rotate = item.azimuth;

      if(this.$refs.map.onIsMarker(mapDataType, id ) ) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_LIVE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          if(res.data.data.lng != null && res.data.data.lng != "" && res.data.data.lat != null && res.data.data.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(res.data.data.lng, res.data.data.lat)
          } else {
            this.$refs.map.onAddressToMoveCenter(res.data.data.address);
          }
        }
      });
    },
    reset() {
      this.endYmd = null;
      this.startYmd = null;
      this.keyword = null;
      this.keywordType = "all";
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
