<template>
  <div>
    <div class="set-toggle">
      <h4>Follow Route</h4>
      <label class="switch">
        <input
            type="checkbox"
            v-model="selectCameraType.followRoute"
            @change="checkFollowRoute">
        <span class="slider round"></span>
      </label>
    </div>

    <p v-if="selectCameraType.followRoute" class="message-warning">
      'WIDE', 'ZOOM', 'IR' 설정을 변경하려면 Follow Route를 비활성화하세요.
    </p>

    <ul class="set-sub-toggle">
      <li v-for="(option, i) in cameraTypeList" :key="i">
        <h4>{{ option }}</h4>
        <label class="switch">
          <input
              type="checkbox"
              v-model="selectCameraType[option.toLowerCase()]"
              :disabled="selectCameraType.followRoute"
              @change ="sendTakePhoto"
          >
          <span class="slider round"></span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TakePhoto",
  props: {
    propsSelectIndex : {
      type: Number,
      default: -1
    },
    propsCameraType: {
      type: Array,
      default: null
    },
    propsSelectMarkerIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      selectedIndex : -1, //선택된 액션 인덱스
      selectMarkerIndex : -1, //마커의 번호.
      takePhotoData : [],
      selectCameraType : {
        followRoute: true,
        wide: true,
        zoom: true,
        ir: true,
      },
      cameraTypeList :['WIDE', 'ZOOM', 'IR'],
    }
  },
  mounted() {
    if(this.propsCameraType !== null) {
      this.onSettingData(this.propsSelectMarkerIndex,this.propsSelectIndex,this.propsCameraType)
    }
  },
  watch: {
    'selectCameraType.followRoute': function (newVal) {
      if (newVal) {
        // followRoute가 true일 때 다른 속성들도 모두 true로 설정
        this.selectCameraType = {
          followRoute: true,
          wide: true,
          zoom: true,
          ir: true,
        };
      }
    },
  },

  methods : {
    checkFollowRoute() {
      // this.settingFollowRoute()
      this.sendTakePhoto()
    },



    /**
     * 1.부모로부터 내가 설정해야하는 데이터 양식을 받는다.
     * 2.양식의 데이터에 값이 있으면 setSelectCameraType를 실행한다.
     * 3. 데이터가 없거나 null일 경우 초기값으로 설정후 부모한테 전달한다. isInit을 실행
     * */
    onSettingData(selectMarkerIndex, selectedIndex, data){
      console.log("takePhoto-onSettingData!", selectMarkerIndex, selectedIndex,data)
      this.selectedIndex = selectedIndex
      this.takePhotoData = data
      this.selectMarkerIndex = selectMarkerIndex
      this.isInit()
    },

    isInit(){
      console.log("isInit run!")
      let isFirst = false
      this.takePhotoData.forEach(item =>{
        if( item.actionName == "fileSuffix" && item.actionData == null){
          isFirst = true
          this.selectCameraType.followRoute = true
          //     = {
          //   followRoute: true,
          //   wide: true,
          //   zoom: true,
          //   ir: true,
          // }
        }
      })

      /**
       * 처음으로 만들어지는거면 데이터 설정후 부모한테 데이터를 전달한다.
       * 데이터가 세팅되어있는 경우엔 부모로부터 데이터를 받아서 자식이 데이터 세팅을 한다.
       * */
      if(isFirst) this.sendTakePhoto()
      else this.setSelectCameraType()
    },
    /**
     * 부모로 부터 받은 데이터를 세팅하는 함수.
     * */
    setSelectCameraType() {
      this.takePhotoData.forEach((item) => {
        if (item.actionName === "payloadLensIndex") {

          this.cameraTypeList.forEach(item => {
            this.$set(this.selectCameraType, item.toLowerCase(), false);
          })
          if (item.actionData != null && item.actionData != undefined && item.actionData != '') {
            const data = item.actionData.split(',');
            if (data.length != 0) {

              data.forEach((key) => {
                this.$set(this.selectCameraType, key, true);
                console.log(key,this.selectCameraType[key])
              });
            }
          }
        }

        if (item.actionName === "useGlobalPayloadLensIndex") {
          if (item.actionData != null || item.actionData != undefined) {
            if(item.actionData == 1){
              ["followRoute", "wide", "ir", "zoom"].forEach((key) => {
                this.$set(this.selectCameraType, key, true);
              });
            }else{
              this.$set(this.selectCameraType, "followRoute", false);
            }
          }else{
            this.$set(this.selectCameraType, "followRoute", true);
          }
        }
      });
    },

    /**
     * takePhotoData에 사용자가 설정한 데이터를 저장한 후 modal로 전달한다.
     * */
    sendTakePhoto(){
      this.takePhotoData.forEach(item =>{
        if( item.actionName == "fileSuffix"){
          item.actionData = crypto.randomUUID();
        }

        if( item.actionName == "payloadLensIndex"){
          const cameraTypes = []
            this.cameraTypeList.forEach(camera=>{
              const key = camera.toLowerCase()
              if(this.selectCameraType[key]){
                cameraTypes.push(key)
              }
            })
          const result = cameraTypes.join(',')
          if(item.actionData != result) item.actionData = result
        }
        
        if( item.actionName == "useGlobalPayloadLensIndex"){
          const data = this.selectCameraType.followRoute ? 1 : 0
          if(data !=  item.actionData) item.actionData = data
        }
      })
      this.$emit("takePhotoData", this.selectedIndex,  JSON.parse(JSON.stringify(this.takePhotoData)))
    },

    clear(){
      this.selectedIndex = -1
      this.selectMarkerIndex = -1
      this.takePhotoData = []
      
      this.selectCameraType = {
        followRoute: true,
        wide: true,
        zoom: true,
        ir: true,
      }
    },

  }

}
</script>

<style scoped>

.set-toggle{display: flex;justify-content: space-between;align-items: center;padding-bottom: 15px;border-bottom: 1px solid #ddd;}
.set-toggle > h4{font-size: 1.4rem;font-weight: 500;}
.set-content{width: 500px;padding: 15px;}
.set-sub-toggle{padding: 15px 0;}
.set-sub-toggle > li{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.set-sub-toggle > li > h4{font-size: 1.4rem;font-weight: 500;}
.switch {position: relative; display: inline-block;width: 40px;height: 20px;}
</style>