<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-title">
          <h3 style="margin-bottom: 0;">미션 액션 설정</h3>
          <button @click="closeModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon"
              style="width: 15px;" /></button>
        </div>
        <div class="modal-content">
          <div class="camera-data-info">
            <h3>설정 목록 <span>{{ missionActions.length }}</span>개</h3>
            <button @click="addNewSetting()"><img src="@/assets/images/ic_action_add.png" alt="icon" />추가하기</button>
          </div>
          <div class="camera-set">
            <!--typeSelect-->
            <div class="set-list" v-if="missionActions.length > 0">
              <div class="set-list-box" v-for="(action, index) in missionActions" :key="index"
                   @click="selectSetting(index,action)" :class="{ active: index === selectedIndex }">
                <div class="set-list-value">
                  <h4>{{ index + 1 }}.</h4>
                  <h6>{{ missionActionList.length }}</h6>
                  <select :value="action.actionType" @change="updateSetting(index, $event.target.value)">
                    <option :key="'action_' + actionIndex" v-for="(action2, actionIndex) in missionActionList"
                      :value="action2.actionType">
                      {{ action2.actionType !== undefined ? action2.actionType : '-' }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="set-content" v-if="missionActions.length > 0 ">
              <!--              type별 Component를 만들어서 호출하도록 관리해야함. component화 가능-->
              <div class="set-content-title" >
                <h2 style="margin: 0">{{ missionActions[selectedIndex].actionType }}</h2>
                <button @click="removeSetting(selectedIndex)"><img src="@/assets/images/ic_action_del.png" alt="icon" />삭제하기</button>
              </div>
                <TakePhoto v-show="missionActions[selectedIndex].actionType === 'TakePhoto'" ref="TakePhoto" @takePhotoData="saveActionElementItem" :props-camera-type="missionActions[selectedIndex].actionElementList" :props-select-marker-index="selectMarkerIndex" :props-select-index="selectedIndex" />
                <PanoShot v-show="missionActions[selectedIndex].actionType === 'PanoShot'" ref="PanoShot" @panoShotData="saveActionElementItem" :props-camera-type="missionActions[selectedIndex].actionElementList" :props-select-marker-index="selectMarkerIndex" :props-select-index="selectedIndex"/>
            </div>
          </div>
        </div>
        <div class="modal-btn">
          <button class="btn-save" @click="saveSettings">확인</button>
          <button @click="closeModal">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMissionActionList } from "@/api/mission";
import TakePhoto from "@/components/mission/action/TakePhoto.vue"
import PanoShot  from "@/components/mission/action/PanoShot.vue"

export default {
  name: "Modal.vue",
  components: { TakePhoto, PanoShot  },
  data() {
    return {
      isModalShow: false,
      selectedIndex: 0, //선택된 액션 인덱스
      selectActionType: "None",
      selectMarkerIndex: -1, //마커의 번호.
      missionActions: [],
      missionActionList: [],
    }
  },
  mounted() {
  },
  created() {
    this.getMissionActionList()
  },
  beforeDestroy() {
    console.log("Modal destroy!")
  },

  methods: {
    getMissionActionList() {
      getMissionActionList().then((res) => {
        if (res.data.result == 0) {

          /**변수명 변경*/
          const actionList = res.data.data.map(data => {
            const actionElementList = data.elements.map(element => {
              return {
                "actionName": element.actionName,
                "valueType": element.missionActionValueType,
                "actionData": null,
              }
            })
            return {
              "actionType": data.actionType,
              "actionElementList": actionElementList,
            }
          })

          /**사용가능한 actionType 추출해서 넣기.
           * 모든 액션이 가능해지면 tempList에서 filter 하는게 아니라
           * this.missionActionList = actionList로 처리 가능.
           * */
          const tempList = actionList.filter(action => {
            /**
             * 추후 wayPoint의 가능 action이 늘어날 경우 아래에 추가하기.
             * 모든 액션기능이 추가될시 필터안걸고 그냥 넣어주면됌
             * */
          //  if (action.actionType == 'None') return
            if (action.actionType == 'TakePhoto') return action
            if (action.actionType =='PanoShot') {return action}
          })

          this.missionActionList = tempList

        }
      })
    },

    /**
     * 신현호
     * 모달 오픈할떄 이전에 저장된 prevAction을 받습니다.
     * 저장된 값이 있다면 setSetting() 을 수행합니다.
     */
    openModal(index, prevAction) {
      this.selectMarkerIndex = index
      this.isModalShow = true

      // 저장된 데이터가 있는경우 즉, 수정인 경우
      if(prevAction !== undefined) {
        this.setSetting(prevAction)
      }

      this.sendIsShowModal()
    },

    /**
     * 선택된 애들로 값을 초기화 해줘야 합니다.
     */
    setSetting(actions) {
      actions.forEach((action) => {
        this.addNewSetting(action)
      })
    },

    closeModal() {
      this.isModalShow = false
      this.clear()
      this.sendIsShowModal()
    },

    /**
     * 이전에 추가한 액션이 있을경우 prevAction에 추가가됌
     * @param prevAction 이전에 추가했던 action 없을경우 takePhoto
     */
    addNewSetting(prevAction=null) {
      // api에서 type 받은 후에 해당 객체 복사해서 넣어주기.
      this.selectActionType = prevAction !== null ? prevAction.actionType : 'TakePhoto'
      const action = {
          "index": prevAction !== null ? prevAction.index : this.missionActions.length,
          "missionLocationIndex": this.selectMarkerIndex,
          "actionType": JSON.parse(JSON.stringify(this.selectActionType)),
          "actionElementList": JSON.parse(JSON.stringify(prevAction !== null ? prevAction.actionElementList : this.missionActionList[0].actionElementList))
      }

      this.missionActions.push(action)
      // 현재 클릭된 애 파란색으로 하는거
      this.selectedIndex = this.missionActions.length - 1;
    },

    /**
     * selectSetting 작동후 updateSetting이 작동된다.
     * 액션항목에서 액션 눌러지면 된다.
     *  액션에서 다른 액션으로 이동할때 해당 index 데이터를 찾아서 넘겨주고 세팅함.
     * */
    selectSetting(index,action) {
      if(this.selectedIndex === index) return

      this.selectedIndex = index;
      const actionType = action.actionType
      switch (actionType) {
        case "TakePhoto":
          this.$refs[actionType].onSettingData(this.selectMarkerIndex, this.selectedIndex, this.missionActions[this.selectedIndex].actionElementList)
          break;
        case "PanoShot":
          this.$refs[actionType].onSettingData(this.selectMarkerIndex, this.selectedIndex, this.missionActions[this.selectedIndex].actionElementList)
          break;
      }
    },

    /**
     * selectSetting 작동후 updateSetting이 작동된다.
     * 액션항목에서 액션의 종류가 변경되면 작동한다.
     * */
    updateSetting(index, action) {
      const findItem = this.missionActionList.find(item => {
        return item.actionType == action
      })


      if (findItem != undefined && findItem != null) {
        const actionElementList = JSON.parse(JSON.stringify(findItem.actionElementList)) //메모리 객체참조 문제발생함.
        this.missionActions[index].actionElementList = actionElementList
        this.missionActions[index].actionType = findItem.actionType
        if(action != "None"){
          this.$refs[action].clear()
          this.$refs[action].onSettingData(this.selectMarkerIndex, index, actionElementList)
        }
      }
    },

    removeSetting(index) {
      this.missionActions.splice(index, 1);
      if (index - 1 < 0) {
        this.selectedIndex = 0
      } else {
        this.selectedIndex = index - 1
      }
    },
    saveSettings() {
      this.$emit("saveActionItem", this.selectMarkerIndex,this.missionActions)
      this.closeModal()
    },

    sendIsShowModal() {
      this.$emit("isShowModal", this.isModalShow);
    },

    clear() {
      this.isModalShow = false
      this.selectedIndex = 0

      this.selectActionType = "None"
      this.selectMarkerIndex = -1
      this.missionActions = []
      //this.missionActionList = []
    },

    saveActionElementItem(selectedIndex, actionData) {
      this.missionActions[selectedIndex].actionElementList = actionData
    }
  }
}
</script>

<style scoped>
/* modal start */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  width: auto;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.modal-title>h3 {
  font-size: 1.6rem;
}

.modal-title>button {
  border: 0;
}

.modal-btn {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.modal-btn>button {
  margin: 0 5px;
  width: 120px;
  height: 35px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #969696;
  font-size: 1.4rem;
}

.modal-btn>button.btn-save {
  background: #0080ff;
  color: #fff;
  border: 1px solid #0080ff;
}

.camera-data-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.camera-data-info>h3 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #969696;
}

.camera-data-info>h3>span {
  color: #0080ff;
}

.camera-data-info>button {
  border: 0;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #969696;
}

.camera-data-info>button>img {
  margin-right: 5px;
  padding-top: 2px;
}

.camera-set {
  display: flex;
  border-bottom: 1px solid #ddd;
  height: 350px;
  width: 715px;
}

.set-list {
  height: 350px;
  max-height: 350px;
  overflow-y: auto;
  padding: 15px 15px 15px 0px;
  border-right: 1px solid #ddd;
}

.set-list-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.set-list-box.active {
  border-color: #007bff;
}

.set-list-value {
  display: flex;
  align-items: center;
}

.set-list-value>h4 {
  font-size: 1.4rem;
}

.set-list-value>select {
  border: 0;
  margin-left: 5px;
  font-size: 1.4rem;
  font-weight: 500;
}

.set-list-box>h5 {
  font-size: 1.2rem;
  color: #969696;
  font-weight: 300;
  padding-left: 28px;
  margin-top: 5px;
}

.set-content {
  width: 500px;
  padding: 15px;
}

.set-content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.set-content-title>h3 {
  font-size: 1.4rem;
}

.set-content-title>button {
  border: 0;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #969696;
}

.set-content-title>button>img {
  margin-right: 5px;
  padding-top: 2px;
}




/* 토글 슬라이더 */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-title {
  margin-left: 30px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
  color: #c9c9c9;
  font-size: 1.2rem;
}

.action-mark {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #bbbbbb;
  margin-right: 5px;
}

.action-mark.mark-on {
  background: #0ccd49;
}

.message-warning {
  color: #969696;
  font-size: 1.2rem;
  margin-top: 10px;
  padding: 10px;
  background: #eee;
  border-radius: 5px;
  text-align: center;
}

/* modal end */
</style>