<template>
  <div>
    <div class="contents liveInfo" style="padding: 0;">
      <div class="flexB" style="margin-bottom: 0px;">
        <ul class="flex liveTab">
          <li :class="{ active: visible == 1 ? true : false }">
            <a @click="handleTab(1)">Mission</a>
          </li>
          <li :class="{ active: visible == 2 ? true : false }">
            <a @click="handleTab(2)">Create</a>
          </li>
          <li :class="{ active: visible == 3 ? true : false }">
            <a @click="handleTab(3)">Upload</a>
          </li>
          <div class="last_line"></div>
        </ul>
        <!--위치기반검색 버튼-->
        <!--<button class="small">
           <a @click="handleTab(8)">{{ $t("253") }}</a>
         </button>-->
      </div>

      <Mission v-if="visible == 1" :eventId="id" />
      <MissionCreate v-if="visible == 2" :eventId="id" @waypoint-create-success="waypointCreateSuccess" />
      <MissionUpload v-if="visible == 3" :eventId="id" />
    </div>
  </div>
</template>
<script>
import { fetchEvent } from "@/api/event";

import Mission from "./missionFlight/MissionView";
import MissionCreate from "./missionFlight/MissionCreate";
import MissionUpload from "./missionFlight/MissionUpload";

export default {
  components: { Mission, MissionUpload ,MissionCreate},
  name: "LiveInfo",
  props: ["eventId"],
  data() {
    return {
      visible: null,
      editMode: false,
      event: {},
      id: "",
      department: {},
    };
  },
  created() {
    this.id = this.$route.query.eventId;
    this.$store.dispatch("store/SET_NAVBAR", { nav: 2, sub: 0 });
  },
  mounted() {
    this.getEventDetail();
  },
  methods: {
    preset(){
      let id = localStorage.getItem("LiveEventInfoSub");
      if(id != undefined) {
        this.visible = id;
      } else {
        if(this.visible == null){
          this.visible = 1;
        }
        localStorage.setItem("LiveEventInfoSub", this.visible);
      }
    },
    waypointCreateSuccess(){
      this.handleTab(1);
    },
    handleRefresh() {
      this.$router.go();
    },
    handleTab(id) {
      this.visible = id;
      localStorage.setItem("LiveEventInfoSub", this.visible);
    },
    getEventDetail() {
      if(this.id == undefined) {
        return;
      }
      fetchEvent(this.id).then((res) => {
        this.event = res.data.data;
        this.department = res.data.data.managerDepartment;
        this.preset();
      });
    },
  },
};
</script>
