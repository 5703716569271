<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 100px);">
    <div class="flex">
    <LiveMapList  v-if="visible == 1" :eventId = "eventId" @select-live-map="handleDetail"/>
    </div>
    <LiveMapDetail  v-if="visible == 2" :eventId = "eventId"  :waypointId="waypointId" :refId = "refId" :contentsId = "contentsId" @back-list="backToLiveMapList" />
  </div>

</template>
<style>
div.liveMapMark {
  position: absolute;
  width: 60px;
  height: 18px;
  right: 12px;
  top: 9px;
  padding: 2px 0;
  text-align: center;
  background-color: #ffc002;
  border-radius: 9px;
}
span.markTxt {
  width: 27px;
  height: 15px;
  font-family: Noto Sans KR;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}
</style>
<script>
import moment from "moment";
import LiveMapList from "./LiveMapList";
import LiveMapDetail from "./LiveMapDetail";

export default {
  components: { LiveMapList, LiveMapDetail },
  props: {
    eventId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      index: 0,
      moment: moment,
      visible: 1,
      waypointId : null,
      refId: null,
      contentsId : null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 5 });
  },
  mounted() {
  },
  methods: {
    handleDetail(id, refId,contentsId) {
      this.waypointId = id;
      this.refId = refId;
      this.contentsId = contentsId;
      this.visible = 2;
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getContentsList();
    },
    backToLiveMapList() {
      this.visible = 1;
    },
  },
};
</script>
