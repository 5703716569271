
<template>
  <div>
    <div class="set-toggle">


    </div>

    <ul class="set-sub-toggle">
      <li v-for="(option, i) in cameraTypeList" :key="i">
        <h4>{{ option }}</h4>
        <label class="switch">
          <input
              type="checkbox"
              v-model="selectCameraType[option.toLowerCase()]"
              :disabled="selectCameraType.followRoute"
              @change ="sendPanoShot"
          >
          <span class="slider round"></span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PanoShot",
  data() {
    return {
      selectedIndex : -1, //선택된 액션 인덱스
      selectMarkerIndex : -1, //마커의 번호.
      panoShotData : [],
      selectCameraType : {
        wide: true,
        zoom: true,
        ir: true,
      },
      cameraTypeList :['WIDE', 'ZOOM', 'IR'],
    }
  },
  props: {
    propsSelectIndex : {
      type: Number,
      default: -1
    },
    propsCameraType: {
      type: Array,
      default: null
    },
    propsSelectMarkerIndex: {
      type: Number,
      default: -1
    }
  },
  mounted() {
    if(this.propsCameraType !== null) {
      this.onSettingData(this.propsSelectMarkerIndex,this.propsSelectIndex,this.propsCameraType)
    }
  },
  methods : {

    /**
     * 1.부모로부터 내가 설정해야하는 데이터 양식을 받는다.
     * 2.양식의 데이터에 값이 있으면 setSelectCameraType를 실행한다.
     * 3. 데이터가 없거나 null일 경우 초기값으로 설정후 부모한테 전달한다. isInit을 실행
     * */
    onSettingData(selectMarkerIndex, selectedIndex, data){

      this.panoShotData = data
      this.selectMarkerIndex = selectMarkerIndex
      this.selectedIndex = selectedIndex
      this.isInit()
    },

    isInit(){
      let isFirst = false
      this.panoShotData.forEach(item =>{
        if(item.actionData == null){
          isFirst = true
          this.selectCameraType = {
            wide: true,
            zoom: true,
            ir: true,
          }
        }
      })

      /**
       * 처음으로 만들어지는거면 데이터 설정후 부모한테 데이터를 전달한다.
       * 데이터가 세팅되어있는 경우엔 부모로부터 데이터를 받아서 자식이 데이터 세팅을 한다.
       * */
      if(isFirst) this.sendPanoShot()
      else this.setSelectCameraType()
    },

    /**
     * 부모로 부터 받은 데이터를 세팅하는 함수.
     * */
    setSelectCameraType() {
      this.panoShotData.forEach((item) => {
        if (item.actionName === "payloadLensIndex") {

          this.cameraTypeList.forEach(item => {
            this.$set(this.selectCameraType, item.toLowerCase(), false);
          })

          if (item.actionData != null && item.actionData != undefined && item.actionData != '') {
            const data = item.actionData.split(',');
            if (data.length != 0) {
              data.forEach((key) => {
                this.$set(this.selectCameraType, key, true);
              });
            }
          }
        }

        if (item.actionName === "useGlobalPayloadLensIndex") {
          if (item.actionData != null || item.actionData != undefined) {
            if(item.actionData == 1){
              this.cameraTypeList.forEach((key) => {
                this.$set(this.selectCameraType, key, true);
              });
            }
          }
        }
      });
    },

    /**
     * modal로 데이터 전달.
     */
    sendPanoShot(){
      this.panoShotData.forEach(item =>{
        if( item.actionName === "payloadLensIndex"){
          const cameraTypes = []
          this.cameraTypeList.forEach(camera=>{
            const key = camera.toLowerCase()
            if(this.selectCameraType[key]){
              cameraTypes.push(key)
            }
          })
          const result = cameraTypes.join(',')
          if(item.actionData != result) item.actionData = result
        }

        if( item.actionName == "useGlobalPayloadLensIndex"){
          item.actionData = 0
        }

        if(item.actionName == "panoShotSubMode"){
          item.actionData = "panoShot_360"
        }
      })
      this.$emit("panoShotData", this.selectedIndex,  JSON.parse(JSON.stringify(this.panoShotData)))
    },

    clear(){
      this.selectedIndex = -1
      this.selectMarkerIndex = -1
      this.panoShotData = []

      this.selectCameraType = {
        wide: true,
        zoom: true,
        ir: true,
      }
    },
  }

}
</script>





<style scoped>

</style>